<template>
  <div>

    <user-list-add-new
        :is-add-new-user-sidebar-active.sync="isAddNewUserSidebarActive"
        :role-options="roleOptions"
        :empresa-options="listaEmpresas"
        @refetch-data="refetchData"
    />
    <!-- Table Container Card -->
    <b-card
        no-body
        class="mb-0"
    >
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
              cols="12"
              md="6"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Exibição</label>
            <v-select
                v-model="perPage"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="perPageOptions"
                :clearable="false"
                class="per-page-selector d-inline-block mx-50"
            />
          </b-col>
          <!-- Search -->
          <b-col
              cols="12"
              md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                  v-model="searchQuery"
                  class="d-inline-block mr-1"
                  placeholder="Pesquisar..."
              />
              <b-button
                  v-if="$can('salvar', 'usuários')"
                  variant="success"
                  @click="isAddNewUserSidebarActive = true"
              >
                <span class="text-nowrap">
                  Adicionar
                </span>
              </b-button>
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
          ref="refUserListTable"
          class="position-relative font-size-9"
          :items="fetchUsers"
          responsive
          :fields="tableColumns"
          primary-key="id"
          :sort-by.sync="sortBy"
          show-empty
          empty-text="Nenhum resultado encontrado"
          :sort-desc.sync="isSortDirDesc"
          :busy="isLoad"
          striped
      >
        <template #table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner class="align-middle"/>
            <strong> Carregando...</strong>
          </div>
        </template>
        <!-- Column: User -->
        <template #cell(nome)="data">
          <b-media vertical-align="center">
            <template #aside>

              <b-avatar
                  size="4.5em"
                  :src="data.item.imagem"
                  :text="avatarText(data.item.name)"
                  :variant="`light-${resolveUserRoleVariant(data.item.role)}`"
              />
            </template>

            {{ data.item.name }}
            <small class="text-muted d-block">{{ data.item.funcao }}</small>
            <small class="text-muted d-block text-uppercase">{{ data.item.setor }}</small>
          </b-media>
        </template>

        <!-- Column: Role -->
        <template #cell(perfil)="data">
          <div>
            <feather-icon
                :icon="data.item && resolveUserRoleIcon(data.item.roles[0])"
                size="18"
                class="mr-50"
                :class="`text-${resolveUserRoleVariant(data.item.roles[0])}`"
            />
            <span
                v-for="(item, index) in data.item.roles"
                :key="index"
                class="align-text-top"
            >{{ item }} </span>
          </div>
        </template>
        <template #cell(classes)="data">
          <b-media vertical-align="center">

            <div
                v-for="(item, index) in data.item.classes"
                :key="index"
                class="d-block font-weight-bold"
            >{{ item.abreviacao }}
            </div>

          </b-media>
        </template>
        <template #cell(empresas)="data">
          <b-media vertical-align="center">

            <small
                v-for="(item, index) in data.item.empresas"
                :key="index"
            >{{ item.nome }} </small>

          </b-media>
        </template>

        <!-- Column: Actions -->
        <template #cell(ações)="data">
          <b-dropdown
              v-if="$can('atualizar', 'usuários')"
              variant="link"
              no-caret
              :right="$store.state.appConfig.isRTL"
          >

            <template #button-content>
              <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
              />
            </template>

            <b-dropdown-item
                v-if="$can('atualizar', 'usuários')"
                :to="{ name: 'apps-users-edit', params: { id: data.item.uuid } }"
            >

              <feather-icon icon="EditIcon"/>
              <span class="align-middle ml-50">Editar</span>
            </b-dropdown-item>
            <b-dropdown-item
                v-if="$can('atualizar', 'usuários')"
                @click="modalResetar(data.item)"
            >

              <feather-icon
                  icon="LockIcon"
              />
              <span class="align-middle ml-50">Resetar Senha</span>
            </b-dropdown-item>

            <b-dropdown-item
                v-if="$can('excluir', 'usuários')"
                @click="modalExcluir(data.item)"
            >
              <feather-icon icon="TrashIcon"/>
              <span class="align-middle ml-50">Excluir</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Exibindo {{ dataMeta.from }} a {{ dataMeta.to }} dos {{ dataMeta.of }} resultados
              encontrados</span>
          </b-col>
          <!-- Pagination -->
          <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
                v-model="currentPage"
                :total-rows="totalUsers"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
    <modalConfirmacao
        titulo="Excluir Item"
        :item-id="dadosUsuario.id"
        :item-nome="dadosUsuario.name"
        @itemConfirmado="excluirItem"
        @click="modalExcluir(data.item)"
    />
    <modalConfirmacao
        id-modal="modal-reset"
        titulo="Resetar Senha"
        text-button-confirm="Resetar Senha"
        :item-id="dadosUsuario.id"
        :item-nome="dadosUsuario.name"
        text-secondary="A nova senha será : meloplanning@123 "
        @itemConfirmado="resetarItem"
        @click="modalResetar(data.item)"
    />
  </div>
</template>

<script>
import { avatarText } from '@core/utils/filter'
import {
  BAvatar,
  BButton,
  BCard,
  BCol,
  BDropdown,
  BDropdownItem,
  BFormInput,
  BMedia,
  BPagination,
  BRow,
  BSpinner,
  BTable,
} from 'bootstrap-vue'
import { onUnmounted, ref } from '@vue/composition-api'
import modalConfirmacao from '@/views/components/componentesPensou/ModalConfirmacao.vue'
import store from '@/store'
import vSelect from 'vue-select'

import SetoresDropdown from '@core/layouts/components/app-navbar/components/SetoresDropdown.vue'
import UserListAddNew from './UserListAddNew.vue'
import userStoreModule from '../userStoreModule'
import useUsersList from './useUsersList'

export default {
  components: {
    SetoresDropdown,
    UserListAddNew,
    modalConfirmacao,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BDropdown,
    BDropdownItem,
    BPagination,
    BSpinner,

    vSelect,
  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = 'app-user'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    const isAddNewUserSidebarActive = ref(false)

    const roleOptions = [

      {
        label: 'COORDENAÇÃO',
        value: 'Coordenação',
      },
      {
        label: 'LÍDER',
        value: 'Lider',
      },
      {
        label: 'COLABORADOR',
        value: 'Colaborador',
      },

    ]

    const listaEmpresas = ref([])

    store.dispatch('app-user/fetchEmpresas')
        .then(response => {
          listaEmpresas.value = response.data
        })

    const {
      fetchUsers,
      fetchRoles,
      deleteUser,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,
      resetUser,
      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,
    } = useUsersList()

    return {

      // Sidebar
      isAddNewUserSidebarActive,
      deleteUser,
      resetUser,
      fetchUsers,
      fetchRoles,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,
      listaEmpresas,
      // Filter
      avatarText,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      roleOptions,
      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,
    }
  },
  data() {
    return {
      dadosUsuario: {
        id: '',
        name: '',
      },
    }
  },
  computed: {
    isLoad() {
      return this.$store.state['app-user'].load
    },

  },
  methods: {
    modalExcluir(item) {
      this.dadosUsuario.name = item.name
      this.dadosUsuario.id = item.uuid
      this.$root.$emit('bv::show::modal', 'modal-confirmacao')
    },
    excluirItem(dados) {
      this.deleteUser(dados)
    },
    modalResetar(item) {
      this.dadosUsuario.name = item.name
      this.dadosUsuario.id = item.uuid
      this.$root.$emit('bv::show::modal', 'modal-reset')
    },
    resetarItem(dados) {
      this.resetUser(dados)
    },

  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
